import { createActionGroup, emptyProps } from '@ngrx/store';

export const simpleSurveyAction = createActionGroup({
  source: 'Simple survey',
  events: {
    'load simple survey': emptyProps(),

    'show expired': emptyProps(),

    'save progress': emptyProps(),
    'save progress success': emptyProps(),

    'submit answer': emptyProps(),
    'submit answer success': emptyProps(),
  },
});
