import {
  ChangeContext,
  NgxSliderModule,
  SliderComponent,
} from '@angular-slider/ngx-slider';
import {
  Component,
  DestroyRef,
  Directive,
  Input,
  OnInit,
  ViewChild,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EMPTY, Observable, startWith } from 'rxjs';
import { FormFieldBase } from '../../core/form-field/form-field-base.directive';
import { SurveyQuestion } from '../../models';
import { logger } from '../../utils';
import { CustomFormStatus, getStatusClass } from '../utils';

@Component({
  selector: 'lib-slider-base',
  templateUrl: './slider-base.component.html',
  styleUrls: ['./slider-base.component.scss'],
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, NgxSliderModule],
})
export class SliderBaseComponent {
  @Input() statusClass = '';
  @Input({ required: true }) question!: SurveyQuestion;
}

@Directive()
export class SliderBaseDirective extends FormFieldBase implements OnInit {
  @Input({ required: true }) question!: SurveyQuestion;
  @Input() isScrolling$: Observable<boolean> = EMPTY;
  @Input() sliderClass?: string;

  initialValue!: number;
  statusClass!: CustomFormStatus;

  @ViewChild('slider') slider!: SliderComponent;

  private destroyRef = inject(DestroyRef);

  /** Must be set only when a scroll should cancel the current change */
  private valueBeforeScroll?: number;

  private restoreValueAfterScroll = false;

  override ngOnInit() {
    super.ngOnInit();

    this.setInitialValue();
    this.preventChangeDuringScroll();

    this.control.statusChanges
      .pipe(startWith(null), takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.statusClass = getStatusClass(this.control);
      });
  }

  private setInitialValue() {
    const question = this.question;

    // As the ngx-slider provides 0 as default value when initialized, we cannot directly
    // map its value with our form.
    // So we need to differ any updates using an internal state `value`
    const currentValue: number | undefined = this.control.value;
    if (currentValue !== undefined && currentValue !== null) {
      this.initialValue = currentValue;
    } else if (question.default !== undefined && question.default !== null) {
      this.initialValue = question.default;
    } else {
      this.initialValue =
        question.input_type === 'step'
          ? question.min || 0 // StepPicker
          : Math.round(((question.min || 0) + (question.max || 0)) / 2); // RangePicker
    }
  }

  preventChangeDuringScroll() {
    this.isScrolling$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((isScrolling) => {
        if (isScrolling && this.valueBeforeScroll !== undefined) {
          // Scrolling while we want to cancel any change
          logger.log('preventChangeDuringScroll', this.valueBeforeScroll);
          this.restoreValueAfterScroll = true;
        }
      });
  }

  onChangeStart(_event: ChangeContext) {
    const value = this.slider.value;
    // Any scroll will cancel current change, until next onChangeEnd()
    this.valueBeforeScroll = value;
  }

  onChangeEnd(event: ChangeContext) {
    if (this.restoreValueAfterScroll && this.valueBeforeScroll !== undefined) {
      logger.log('restore value', this.valueBeforeScroll);
      const value = this.valueBeforeScroll;
      this.slider.writeValue(value);
    } else {
      const value = event.value;
      const parentControl = this.control;
      parentControl.setValue(value);
    }

    // Back to normal
    this.valueBeforeScroll = undefined;
    this.restoreValueAfterScroll = false;
  }
}
