import { Injectable, inject } from '@angular/core';
import { SurveyAnswer, SurveyTemplate } from '../../models';
import { AnalyticsSharedService } from '../../services';
import { EligibleResult } from '../eligibility-survey/eligibility-survey.models';
import {
  AnalyticsEvents,
  EligibilityEvent,
  SurveyEvent,
} from './analytics.models';

@Injectable()
export class AnalyticsResources {
  private analyticsService = inject(AnalyticsSharedService);

  sendSurveyEvent(
    eventName: string,
    survey: SurveyTemplate,
    answer: SurveyAnswer
  ) {
    const page = answer.progressPage;
    const questionPages = survey.pages ?? [];
    const pageTitle =
      page === 0
        ? 'intro'
        : page >= questionPages.length
        ? 'submit'
        : questionPages[page - 1]?.title;
    const eventParams: SurveyEvent = {
      reference: survey.reference,
      version: survey.version,
      isFinished: answer.isFinished,
      page,
      pageTitle,
    };
    this.analyticsService.logEvent(eventName, eventParams as any);
  }

  sendEligibilityEvent(result: EligibleResult) {
    const eventParams: EligibilityEvent = {
      result: result.status,
      uid: result.userId,
    };
    this.analyticsService.logEvent(
      AnalyticsEvents.ELIGIBILITY,
      eventParams as any
    );
  }
}
