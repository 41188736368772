import { Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormFieldBase } from '../../core/form-field/form-field-base.directive';
import { InputBaseComponent } from '../input-base/input-base.component';

@Component({
  selector: 'lib-survey-email-input',
  template: `<lib-input-base>
    <input type="email" class="form-control" [formControl]="control" />
  </lib-input-base>`,
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, InputBaseComponent],
})
export class EmailInputComponent extends FormFieldBase {}
