import { Injectable, inject } from '@angular/core';
import { getIdTokenResult } from '@angular/fire/auth';
import { ActivatedRouteSnapshot, CanActivateChildFn } from '@angular/router';
import { LogicError } from '../errors';
import { ErrorHandlerService } from '../services';
import { logger } from '../utils/logger';
import { ForbiddenError, UnauthorizedError } from './errors';
import { SharedGuard } from './shared.guard';

@Injectable({
  providedIn: 'root',
})
export class UserGuardService extends SharedGuard {
  private errorHandler = inject(ErrorHandlerService);

  async canActivate(childRoute: ActivatedRouteSnapshot) {
    logger.log('UserGuard canActivate?');

    const paramMap = childRoute.paramMap;
    const userId = paramMap.get('userId');
    if (!userId) {
      throw new LogicError(
        'This route is secured with UserGuard but it has no `userId` param'
      );
    }
    logger.log('check access for userId', userId);

    const user = await this.getCurrentUser();
    if (!user) {
      logger.log('UserGuard unauthorized');
      throw new UnauthorizedError();
    }

    if (user.uid === userId) {
      logger.log('user is viewing is own survey');
      return true;
    }

    const idToken = await getIdTokenResult(user);
    if (!idToken) {
      logger.log('no idToken'); // should not happen if isAuthenticated
      throw new UnauthorizedError();
    }

    const claims = idToken.claims;
    if (claims['coach'] || claims['admin']) {
      logger.log('user has super claims', claims);
      return true;
    }

    logger.log('UserGuard forbidden');
    throw new ForbiddenError();
  }

  async canActivateWithRedirect(childRoute: ActivatedRouteSnapshot) {
    try {
      return await this.canActivate(childRoute);
    } catch (error) {
      this.redirectOnError(error);
      this.errorHandler.handleError(error);
      throw error;
    }
  }
}

export const UserGuard: CanActivateChildFn = (childRoute) =>
  inject(UserGuardService).canActivate(childRoute);

export const UserGuardWithRedirect: CanActivateChildFn = (childRoute) =>
  inject(UserGuardService).canActivateWithRedirect(childRoute);
