import { NgFor } from '@angular/common';
import { Component, Input, OnInit, inject } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map } from 'rxjs';
import { FormFieldBase } from '../../core/form-field/form-field-base.directive';
import { SurveyQuestion } from '../../models';

@Component({
  selector: 'lib-survey-select-input',
  styleUrls: ['./select-input.component.scss'],
  template: ` <div [formGroup]="innerForm">
    <label *ngFor="let option of question.options" class="select-input__item">
      <mat-checkbox type="checkbox" [formControlName]="option.value">
        {{ option.name }}
      </mat-checkbox>
    </label>
  </div>`,
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, NgFor, MatCheckboxModule],
})
@UntilDestroy()
export class SelectInputComponent extends FormFieldBase implements OnInit {
  private formBuilder = inject(FormBuilder);
  @Input({ required: true }) question!: SurveyQuestion;
  innerForm!: FormGroup<{ [ref: string]: FormControl<boolean | null> }>;

  override ngOnInit() {
    super.ngOnInit();

    this.buildInnerForm();
    this.watchInnerForm().pipe(untilDestroyed(this)).subscribe();
  }

  private buildInnerForm() {
    let currentOptions: string[] | undefined = this.control.value;

    if (!currentOptions?.length) {
      currentOptions = [];
      this.control.setValue(currentOptions); // Update validation errors
    }

    const options: { [key: string]: FormControl<boolean | null> } = {};
    for (const option of this.question.options || []) {
      options[option.value] = this.formBuilder.control(
        currentOptions.includes(option.value)
      );
      if (this.control.disabled) {
        options[option.value].disable();
      }
    }

    const innerForm = (this.innerForm = this.formBuilder.group(options, {
      validators: Validators.required,
      updateOn: 'change',
    }));
    return innerForm;
  }

  private watchInnerForm() {
    return this.innerForm.valueChanges.pipe(
      map((options) => {
        const response: string[] = [];
        for (const [option, enabled] of Object.entries(options)) {
          if (enabled) {
            response.push(option);
          }
        }
        this.control.setValue(response);
        return response;
      })
    );
  }
}
