import { provideEffects } from '@ngrx/effects';
import { AnalyticsSharedService } from '../../services';
import { AnalyticsEffects } from './analytics.effects';
import { AnalyticsResources } from './analytics.resources';

export function analyticsProvider() {
  return [
    provideEffects(AnalyticsEffects),
    AnalyticsResources,
    AnalyticsSharedService,
  ];
}
