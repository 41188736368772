import { Directive, OnDestroy, OnInit, Optional, Self } from '@angular/core';
import {
  ControlContainer,
  ControlValueAccessor,
  FormControl,
  FormControlDirective,
  FormControlName,
  FormGroup,
  NgControl,
  NgModel,
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { LogicError } from '../../errors';

@Directive()
export class FormFieldBase implements OnInit, OnDestroy, ControlValueAccessor {
  private subscription!: Subscription;

  constructor(@Optional() @Self() public ngControl: NgControl) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  control!: FormControl;

  writeValue(): void {
    return undefined;
  }
  registerOnChange(): void {
    return undefined;
  }
  registerOnTouched(): void {
    return undefined;
  }

  ngOnInit() {
    if (!this.ngControl) {
      throw new LogicError('ngControl is undefined');
    }
    if (
      this.ngControl instanceof FormControlName ||
      this.ngControl instanceof FormControlDirective
    ) {
      this.control = this.ngControl.control;
    } else if (this.ngControl instanceof NgModel) {
      this.control = this.ngControl.control;
      this.subscription = this.control.valueChanges.subscribe((_x) => {
        this.ngControl.viewToModelUpdate(this.control.value);
      });
    }
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }
}

@Directive()
export class FormGroupBase {
  get formGroup(): FormGroup {
    return this.controlContainer.control as FormGroup;
  }

  constructor(private controlContainer: ControlContainer) {}
}
