import { CdkStepper, CdkStepperModule } from '@angular/cdk/stepper';
import { NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { SurveyTemplate } from '../../models';
import { SurveyPageHeaderComponent } from '../survey-page-header/survey-page-header.component';
import { SurveyPageLogoComponent } from '../survey-page-logo/survey-page-logo.component';

@Component({
  selector: 'lib-survey-outro',
  templateUrl: './survey-outro.component.html',
  styleUrls: ['./survey-outro.component.scss'],
  standalone: true,
  imports: [
    MatIconModule,
    NgIf,
    CdkStepperModule,
    SurveyPageHeaderComponent,
    MatButtonModule,
    SurveyPageLogoComponent,
  ],
})
export class SurveyOutroComponent {
  @Input() template: SurveyTemplate | null = null;
  @Input({ required: true }) stepper!: CdkStepper;
  @Output() send = new EventEmitter<void>();
  @Input({ required: true }) isDisabled = false;
  previousPage() {
    this.stepper.previous();
  }

  sendResponse() {
    this.send.emit();
  }
}
