import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { EligibleAnswer, EligibleResult } from './eligibility-survey.models';

export const eligibilityActions = createActionGroup({
  source: 'eligibility survey',
  events: {
    'load eligible survey': emptyProps(),

    'check eligibility': emptyProps(),
    'eligibility result': props<{
      eligibleAnswer: EligibleAnswer;
      result: EligibleResult;
    }>(),

    'save eligibility success': props<{
      eligibleAnswer: EligibleAnswer;
      result: EligibleResult;
    }>(),

    resize: emptyProps(),
  },
});
