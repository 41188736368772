<div>
  <h1 style="text-align: center; padding: 10px">Ce questionnaire a expiré</h1>
  <div style="text-align: center">
    <button
      mat-raised-button
      data-test="close-survey"
      class="btn action-button continuer-button"
      (click)="retour()"
    >
      Retour
    </button>
  </div>
</div>
