import { CdkStepper, CdkStepperModule } from '@angular/cdk/stepper';
import { CommonModule, NgFor, NgTemplateOutlet } from '@angular/common';
import { Component } from '@angular/core';
import { StepperScrollerTopDirective } from '../../core/stepper-scroller-top/stepper-scroller-top.directive';

@Component({
  selector: 'lib-survey-stepper',
  templateUrl: './survey-stepper.component.html',
  styleUrls: ['./survey-stepper.component.scss'],
  standalone: true,
  providers: [{ provide: CdkStepper, useExisting: SurveyStepperComponent }],
  imports: [NgTemplateOutlet, CdkStepperModule, NgFor, CommonModule],
  hostDirectives: [StepperScrollerTopDirective],
})
export class SurveyStepperComponent extends CdkStepper {
  readonly showFooter = false; // inject(ENVIRONMENT_CONFIG).isDeveloperMode;

  selectStepByIndex(index: number): void {
    this.selectedIndex = index;
  }
}
