import { createReducer, on } from '@ngrx/store';
import { SurveyAnswer, SurveyTemplate } from '../../models';
import { surveyAction } from './survey.actions';

export interface SurveyState {
  surveyTemplate: SurveyTemplate | null;
  surveyAnswer: SurveyAnswer | null;
  previousSurveyAnswer: SurveyAnswer | null;
  userId: string | null;
  responseId: string | null;
  isLoading: boolean;
  isDisabled: boolean;
  isSubmitted: boolean;
  error: null | string;
}

export const initialState: SurveyState = {
  surveyTemplate: null,
  surveyAnswer: null,
  previousSurveyAnswer: null,
  userId: null,
  responseId: null,
  isLoading: false,
  isDisabled: false,
  isSubmitted: false,
  error: null,
};

export const reducer = createReducer(
  initialState,

  on(surveyAction.loadSurvey, (state) => {
    return { ...state, isLoading: true };
  }),

  on(
    surveyAction.loadSurveySuccess,
    (
      state,
      { surveyTemplate, surveyAnswer, userId, responseId, isDisabled }
    ) => {
      const currentSurveyAnswer = { ...surveyAnswer };
      if (!state.surveyAnswer) {
        if (surveyAnswer.isFinished) {
          // A coach is looking at an answered survey and should start at page 0
          currentSurveyAnswer.progressPage = 0;
        }
      }
      return {
        ...state,
        isLoading: false,
        surveyTemplate: { ...surveyTemplate },
        surveyAnswer: currentSurveyAnswer,
        previousSurveyAnswer: responseId ? { ...surveyAnswer } : null,
        userId: userId ?? null,
        responseId: responseId ?? null,
        isDisabled: isDisabled ?? false,
        isSubmitted: surveyAnswer.isFinished,
      };
    }
  ),

  on(surveyAction.handleError, (state, { error }) => {
    return { ...state, isLoading: false, error: error.message };
  }),

  on(
    surveyAction.changePage,
    surveyAction.changePageSuccess,
    (state, { progressPage }) => {
      return {
        ...state,
        ...(state.surveyAnswer
          ? {
              surveyAnswer: {
                ...state.surveyAnswer,
                progressPage,
              },
            }
          : undefined),
      };
    }
  ),

  on(surveyAction.updateResponse, (state, { responses, modificationDate }) => {
    return {
      ...state,
      ...(state.surveyAnswer
        ? {
            surveyAnswer: {
              ...state.surveyAnswer,
              responses,
              modificationDate,
            },
          }
        : undefined),
    };
  }),

  on(surveyAction.submitResponse, (state, { responses, modificationDate }) => {
    return {
      ...state,
      ...(state.surveyAnswer
        ? {
            surveyAnswer: {
              responseDate: modificationDate,
              ...state.surveyAnswer, // will keep responseDate as it is if already set
              responses,
              modificationDate,
              isFinished: true,
              completionReason: 'answered' as const,
            },
          }
        : undefined),
      isSubmitted: true,
    };
  }),

  on(surveyAction.commitResponse, (state) => {
    return {
      ...state,
      previousSurveyAnswer: state.surveyAnswer
        ? { ...state.surveyAnswer }
        : null,
    };
  }),

  on(surveyAction.updateTemplate, (state, { surveyTemplate }) => {
    return { ...state, surveyTemplate, isLoading: false };
  })
);
