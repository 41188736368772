import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { User } from '../../models';

export const trackingSheetAction = createActionGroup({
  source: 'Tracking Sheet',
  events: {
    'load tracking sheet': emptyProps(),

    'load user success': props<{ user: User }>(),

    'change page': emptyProps(),
    'save progress': props<{ createRevision: boolean }>(),
    'save progress success': props<{ withRevision: boolean }>(),
    'no change': emptyProps(),

    'submit answer': emptyProps(),
    'submit answer success': props<{ withRevision: boolean }>(),

    'handle error': props<{ error: Error }>(),
  },
});
