import { CdkStepperModule, StepperSelectionEvent } from '@angular/cdk/stepper';
import { AsyncPipe, NgFor, NgIf, NgTemplateOutlet } from '@angular/common';
import {
  Component,
  ContentChild,
  EventEmitter,
  HostListener,
  Input,
  NgZone,
  OnInit,
  Output,
  TemplateRef,
  inject,
} from '@angular/core';
import { Timestamp } from '@angular/fire/firestore';
import { FormArray, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import {
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs';
import { SurveyStepperComponent } from '../../components';
import { SurveyBodyComponent } from '../../components/survey-body/survey-body.component';
import {
  SurveyIntroComponent,
  SurveyIntroDirective,
} from '../../components/survey-intro/survey-intro.component';
import { SurveyOutroComponent } from '../../components/survey-outro/survey-outro.component';
import { SurveyPage, SurveyResponses, SurveyTemplate } from '../../models';
import { PageForm, SurveyBuilder, SurveyForm } from '../../services';
import { filterNil } from '../../store';
import ScrollListener from '../../utils/scroll-listener';
import { conditionalQuestions } from '../../validators/conditional-questions';
import { surveyAction } from './survey.actions';
import { surveyAnimation } from './survey.animation';
import { surveyFeature } from './survey.selectors';

/**
 * Customize the survey display and behaviour.
 */
export interface SurveyOptions {
  /** Hide the title header, default is false. */
  hideHeader?: boolean;

  /**
   * Replace the "Next" button by a "Submit" button on the last question page.
   * Default is false: the survey will add a last page dedicated for submission.
   */
  submitOnLastQuestion?: boolean;

  /** Used only if submitOnLastQuestion is true. */
  submitLabel?: string;
}

@Component({
  selector: 'lib-survey',
  standalone: true,
  imports: [
    NgIf,
    AsyncPipe,
    SurveyStepperComponent,
    CdkStepperModule,
    SurveyIntroComponent,
    SurveyBodyComponent,
    NgFor,
    SurveyOutroComponent,
    FormsModule,
    ReactiveFormsModule,
    NgTemplateOutlet,
  ],
  animations: [surveyAnimation],
  providers: [SurveyBuilder],
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.scss'],
})
@UntilDestroy()
export class SurveyComponent implements OnInit {
  private surveyBuilder = inject(SurveyBuilder);
  private store = inject(Store);
  private zone = inject(NgZone);

  @Input() options?: SurveyOptions;
  @Input() username = '';

  @ContentChild(SurveyIntroDirective, { read: TemplateRef })
  surveyIntro?: TemplateRef<any>;

  @Output() surveyLoad = new EventEmitter();
  @Output() submitted = new EventEmitter();
  @Output() pageChanged = new EventEmitter();
  @Output() resized = new EventEmitter();

  /**
   * Listen to any scroll page, so that we can prevent unwanted move of sliders
   * when the user touches the screen.
   */
  scrollListener = new ScrollListener();

  surveyForm: SurveyForm = new FormArray([] as PageForm[]);
  pages: { page: SurveyPage; form: PageForm }[] = [];

  vm$ = combineLatest({
    answer: this.store.select(surveyFeature.selectSurveyAnswer),
    template: this.store.select(surveyFeature.selectSurveyTemplate),
    isLoading: this.store.select(surveyFeature.selectIsLoading),
    isDisabled: this.store.select(surveyFeature.selectIsDisabled),
  });

  ngOnInit() {
    this.store.dispatch(surveyAction.loadSurvey());
    this.surveyLoad.emit();

    this.buildSurveyForm()
      .pipe(
        switchMap(({ template, surveyForm }) =>
          combineLatest([
            this.watchPages(template, surveyForm),
            this.watchConditionalAnswers(template, surveyForm),
            this.watchResponses(surveyForm),
          ])
        ),
        untilDestroyed(this)
      )
      .subscribe();
  }

  private buildSurveyForm() {
    // Rebuild when template updates
    return this.vm$.pipe(
      map(({ template }) => template),
      distinctUntilChanged(),
      withLatestFrom(this.vm$),
      map(([template, { answer, isDisabled }]) =>
        !template || !answer ? null : { template, answer, isDisabled }
      ),
      filterNil(),
      //take(1),
      map(({ template, answer, isDisabled }) => {
        const responses = answer.responses;
        const surveyForm = this.surveyBuilder.buildSurveyForm(
          template,
          responses
        );
        if (isDisabled) {
          surveyForm.disable();
        }
        return { template, surveyForm };
      }),
      tap(({ surveyForm }) => (this.surveyForm = surveyForm))
    );
  }

  private watchPages(survey: SurveyTemplate, surveyForm: SurveyForm) {
    const pages = survey.pages.map((page, i) => ({
      page,
      form: surveyForm.at(i),
    }));
    this.pages = pages;
    return pages;
  }

  private watchConditionalAnswers(
    template: SurveyTemplate,
    surveyForm: SurveyForm
  ) {
    return conditionalQuestions(template, surveyForm).pipe(
      tap(() => this.emitResized())
    );
  }

  private watchResponses(surveyForm: SurveyForm) {
    return surveyForm.valueChanges.pipe(
      debounceTime(10),
      tap(() => this.zone.run(() => this.updateResponse()))
    );
  }

  getResponses() {
    const responses = Object.assign({}, ...this.surveyForm.getRawValue());
    return responses as SurveyResponses;
  }

  onSelectionChange(event: StepperSelectionEvent) {
    this.store.dispatch(
      surveyAction.changePageSuccess({ progressPage: event.selectedIndex })
    );

    this.updateResponse();

    this.pageChanged.emit();
    this.emitResized();
  }

  updateResponse() {
    this.store.dispatch(
      surveyAction.updateResponse({
        responses: this.getResponses(),
        modificationDate: Timestamp.now(),
      })
    );
  }

  submit() {
    this.store.dispatch(
      surveyAction.submitResponse({
        responses: this.getResponses(),
        modificationDate: Timestamp.now(),
      })
    );

    this.submitted.emit();
  }

  emitResized() {
    setTimeout(() => this.resized.emit(), 1); // Give time to the DOM to re-render and adjust its real size
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(event: Event) {
    this.scrollListener.onScroll(event);
  }
}
