import { CustomError } from '../errors';

export class GuardError extends CustomError {
  override isWarning = true;
}

/**
 * Although the HTTP standard specifies "unauthorized", semantically this response means "unauthenticated".
 * That is, the client must authenticate itself to get the requested response.
 */
export class UnauthorizedError extends GuardError {
  constructor(
    message = 'Vous devez être authentifié pour accéder à cette page.'
  ) {
    super(message);
  }
}

/**
 * The client does not have access rights to the content; that is, it is unauthorized,
 * so the server is refusing to give the requested resource. Unlike 401 Unauthorized,
 * the client's identity is known to the server.
 */
export class ForbiddenError extends GuardError {
  constructor(
    message = "Vous n'avez pas les droits pour accéder à cette page."
  ) {
    super(message);
  }
}
