import { Timestamp } from '@angular/fire/firestore';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { SurveyAnswer, SurveyResponses, SurveyTemplate } from '../../models';

export const surveyAction = createActionGroup({
  source: 'Survey',
  events: {
    'load survey': emptyProps(),
    'load survey success': props<{
      surveyTemplate: SurveyTemplate;
      surveyAnswer: SurveyAnswer;
      userId?: string;
      responseId?: string;
      isDisabled?: boolean;
    }>(),

    'change page': props<{
      progressPage: number;
    }>(),
    'next page': emptyProps(),
    'change page success': props<{
      progressPage: number;
    }>(),

    'update response': props<{
      responses: SurveyResponses;
      modificationDate: Timestamp;
    }>(),
    'submit response': props<{
      responses: SurveyResponses;
      modificationDate: Timestamp;
    }>(),
    'commit response': emptyProps(),

    'update template': props<{
      surveyTemplate: SurveyTemplate;
    }>(),

    'handle error': props<{ error: Error }>(),
  },
});
