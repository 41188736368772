import debug from 'debug';

// eslint-disable-next-line no-console
debug.log = console.log.bind(console);

class Logger {
  log = debug('remedee:log');
  error = debug('remedee:error');
}

export const logger = new Logger();
