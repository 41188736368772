import { Observable, debounceTime, filter, map, merge, startWith } from 'rxjs';
import { LogicError } from '../errors';
import { SurveyTemplate } from '../models';
import { QuestionControl, SurveyForm } from '../services';
import { conditionalValidator } from './conditional-validator';

function voidFn() {
  return;
}

export function conditionalQuestions(
  survey: SurveyTemplate,
  surveyForm: SurveyForm
) {
  const conditionalQuestionChanges$: Observable<void>[] = [];

  for (let i = 0, l = survey.pages.length; i < l; i++) {
    const [page, form] = [survey.pages[i], surveyForm.at(i)];
    for (const question of page.questions) {
      const display_condition = question.display_condition;
      if (!display_condition) {
        continue;
      }

      const ref1 = question.reference;
      const control1 = form.get(ref1) as QuestionControl;

      const ref2 = display_condition.ref;
      const control2 = form.get(ref2) as QuestionControl;

      if (!control1 || !control2) {
        throw new LogicError('Invalid display_condition');
      }

      const shouldDisplay = () => {
        const value = control2.value;

        if (Array.isArray(value)) {
          // Check if at least 1 value triggers the condition
          for (const v of value) {
            if (display_condition.values.includes(v as string)) {
              return true;
            }
          }
          return false;
        }

        // Single value
        return display_condition.values.includes(value as string);
      };

      // Make validation conditional
      let validator = control1.validator;
      if (validator) {
        validator = conditionalValidator(shouldDisplay, validator);
        control1.setValidators(validator);
      }

      // Watch the other question to add / remove the control
      const change$ = control2.valueChanges.pipe(
        startWith(control2.value),
        map(() => {
          if (shouldDisplay()) {
            if (!form.contains(ref1)) {
              form.addControl(ref1, control1);
              control1.markAsTouched();
              return true;
            }
          } else {
            if (form.contains(ref1)) {
              form.removeControl(ref1 as never);
              return true;
            }
          }
          return false;
        }),
        filter((change) => change),
        map(voidFn)
      );
      conditionalQuestionChanges$.push(change$);
    }
  }

  return merge(...conditionalQuestionChanges$).pipe(debounceTime(1));
}
