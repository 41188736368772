import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  SliderBaseComponent,
  SliderBaseDirective,
} from '../slider-base/slider-base.component';

@Component({
  selector: 'lib-survey-step-picker',
  template: ` <lib-slider-base [question]="question">
    <ngx-slider
      #slider
      [class]="statusClass"
      [options]="{
        floor: question.min,
        ceil: question.max,
        step: question.step,
        showTicks: true,
        disabled: control.disabled
      }"
      [value]="initialValue"
      (userChangeStart)="onChangeStart($event)"
      (userChangeEnd)="onChangeEnd($event)"
    >
    </ngx-slider>
  </lib-slider-base>`,
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    SliderBaseComponent,
    NgxSliderModule,
  ],
})
export class StepPickerComponent extends SliderBaseDirective {}
