import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';
import { SurveyEffects } from './survey.effects';
import { surveyFeature } from './survey.selectors';

export function surveyProvider() {
  return [
    provideState(surveyFeature),
    provideEffects(SurveyEffects),
    //ErrorHandlerService,
  ];
}
