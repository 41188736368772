import { NgFor } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatRadioModule } from '@angular/material/radio';
import { FormFieldBase } from '../../core/form-field/form-field-base.directive';
import { SurveyQuestion } from '../../models';

@Component({
  selector: 'lib-survey-radio-input',
  styleUrls: ['./radio-input.component.scss'],
  template: ` <div>
    <mat-radio-group [name]="question.reference" [formControl]="control">
      <label *ngFor="let option of question.options" class="select-input__item">
        <mat-radio-button [value]="option.value">
          <span class="select-input__item-label">{{ option.name }}</span>
        </mat-radio-button>
      </label>
    </mat-radio-group>
  </div>`,
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, MatRadioModule, NgFor],
})
export class RadioInputComponent extends FormFieldBase {
  @Input({ required: true }) question!: SurveyQuestion;
  // @Input({ required: true }) form!: UntypedFormGroup;
}
