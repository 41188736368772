import { InjectionToken } from '@angular/core';
import { FirebaseOptions } from '@angular/fire/app';
import { BrowserOptions } from '@sentry/angular-ivy';

export const ENVIRONMENT_CONFIG = new InjectionToken<Environment>(
  'environment'
);

export interface Environment {
  readonly name: string;
  readonly production: boolean;
  readonly firebase: FirebaseOptions;
  readonly functionsBaseUrl: string;
  readonly version: string;
  readonly useEmulators?: boolean;
  readonly isDeveloperMode?: boolean;
  readonly sentry?: Pick<BrowserOptions, 'dsn'>;
}
