import { createReducer, on } from '@ngrx/store';
import { User } from '../../models';
import { trackingSheetAction } from './tracking-sheet.actions';

export interface TrackingSheetState {
  error: null | string;
  user: User | null;
  // TODO: coach
}
export const initialState: TrackingSheetState = {
  error: null,
  user: null,
};

export const reducer = createReducer(
  initialState,

  on(trackingSheetAction.loadUserSuccess, (state, { user }) => {
    return {
      ...state,
      user: { ...user },
    };
  }),

  on(trackingSheetAction.handleError, (state, { error }) => {
    return { ...state, error: error.message };
  })
);
