import { createFeature, createSelector } from '@ngrx/store';
import { isEqual } from 'lodash-es';
import { reducer } from './survey.reducer';

export const surveyFeature = createFeature({
  name: 'survey',
  reducer,
});

export const hasPendingChanges = createSelector(
  surveyFeature.selectSurveyAnswer,
  surveyFeature.selectPreviousSurveyAnswer,
  (answer, previous) => !isEqual(answer?.responses, previous?.responses)
);
