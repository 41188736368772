import { NgFor, NgIf, NgStyle } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { FormFieldBase } from '../../core/form-field/form-field-base.directive';
import { Medication } from '../../models';

@Component({
  selector: 'lib-survey-medications',
  styleUrls: ['./medications.component.scss'],
  templateUrl: './medications.component.html',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    MatRadioModule,
    NgStyle,
    NgFor,
    NgIf,
  ],
})
export class MedicationsComponent extends FormFieldBase implements OnInit {
  /**
   * Displayed medications.
   */
  medications: Medication[] = [];

  newMedication: Medication = this.resetMedication();

  showMedicationForm = false;

  override ngOnInit() {
    super.ngOnInit();
    this.medications = this.control.value || [];
  }

  /**
   * Synchronize the medications (display and form)
   */
  setResponse(medications: Medication[]) {
    this.medications = medications;
    this.control.setValue(medications);
  }

  removeMedication(index: number) {
    const medications = [...this.medications];
    medications.splice(index, 1);
    this.setResponse(medications);
  }

  showNewMedication() {
    this.newMedication = this.resetMedication();
    this.showMedicationForm = true;
  }

  hideNewMedication() {
    this.showMedicationForm = false;
  }

  resetMedication() {
    return {
      medication: {
        name: '',
        dosage: { dosage: null, unit: null },
        quantity: { qte: null, unit: null },
      },
    } as any as Medication;
  }

  addMedication() {
    this.medications.push(this.newMedication);
    this.setResponse(this.medications);
    this.hideNewMedication();
  }
}
