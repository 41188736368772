import { AbstractControl } from '@angular/forms';

export type CustomFormStatus =
  | 'custom-default'
  | 'custom-disabled'
  | 'custom-valid'
  | 'custom-invalid';
export function getStatusClass(form: AbstractControl) {
  if (form.disabled) {
    return 'custom-disabled';
  }
  if (form.valid) {
    const value = form.value;
    // An empty response is not strictly invalid, but not valid neither...
    return value !== undefined && value !== null
      ? 'custom-valid'
      : 'custom-default';
  }
  if (form.touched) {
    return 'custom-invalid';
  }
  // Invalid but not touched yet
  return 'custom-default';
}

function _scrollTo(el: HTMLElement) {
  const bounding = el.getBoundingClientRect();
  if (
    bounding.top < 0 ||
    bounding.bottom >
      (window.innerHeight || document.documentElement.clientHeight)
  ) {
    el.scrollIntoView();
  }
}

export function scrollTo(el: HTMLElement) {
  _scrollTo(el);

  // Wait some time as a focus may show a keyboard
  setTimeout(() => _scrollTo(el), 1000);
}
