import { createActionGroup, emptyProps } from '@ngrx/store';

export const personalSurveyAction = createActionGroup({
  source: 'Personal survey',
  events: {
    'load personal survey': emptyProps(),

    'save progress': emptyProps(),
    'save progress success': emptyProps(),

    'submit answer': emptyProps(),
    'submit answer success': emptyProps(),
  },
});
