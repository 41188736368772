<ng-container
  *ngIf="!(vm$ | async)?.isLoading && (vm$ | async) as vm; else loaderBlock"
>
  <div class="survey__body">
    <lib-survey-stepper
      *ngIf="vm.template && vm.answer"
      #stepper
      (selectionChange)="onSelectionChange($event)"
      [selectedIndex]="vm.answer.progressPage"
      [@enterAnimation]
    >
      <cdk-step>
        <ng-container *ngIf="surveyIntro; else defaultIntroBlock">
          <ng-container *ngTemplateOutlet="surveyIntro"></ng-container
        ></ng-container>
        <ng-template #defaultIntroBlock>
          <lib-survey-intro
            [template]="vm.template"
            (next)="stepper.next()"
          ></lib-survey-intro>
        </ng-template>
      </cdk-step>
      <cdk-step *ngFor="let page of pages; last as isLastPage">
        <lib-survey-body
          [options]="options"
          [template]="vm.template"
          [isLastPage]="isLastPage"
          [isDisabled]="vm.isDisabled"
          [page]="page.page"
          [form]="page.form"
          [isScrolling$]="scrollListener.isScrolling$"
          (next)="stepper.next()"
          (previous)="stepper.previous()"
          (send)="submit()"
        ></lib-survey-body>
      </cdk-step>

      <cdk-step *ngIf="!options?.submitOnLastQuestion">
        <lib-survey-outro
          [stepper]="stepper"
          (send)="submit()"
          [template]="vm.template"
          [isDisabled]="surveyForm.disabled"
        ></lib-survey-outro>
      </cdk-step>
    </lib-survey-stepper>
  </div>
</ng-container>

<ng-template #loaderBlock>
  <div class="loader"></div>
</ng-template>
