import { ErrorHandler, Injectable, inject } from '@angular/core';
import * as SentryType from '@sentry/browser';
import { ENVIRONMENT_CONFIG } from '../core';
import { logger } from '../utils';

declare let Sentry: typeof SentryType | undefined;

@Injectable()
export class SentryService implements ErrorHandler {
  private environment = inject(ENVIRONMENT_CONFIG);

  isEnabled() {
    return (
      this.environment.sentry &&
      !this.environment.useEmulators &&
      !['localhost', '127.0.0.1'].includes(window.location.hostname)
    );
  }

  handleError(error: any) {
    if (typeof Sentry !== 'undefined' && this.isEnabled()) {
      logger.log('send to Sentry', error);
      Sentry.captureException(error);
    }
  }
}
