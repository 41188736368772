import { CdkStepperModule } from '@angular/cdk/stepper';
import { NgIf } from '@angular/common';
import {
  Component,
  Directive,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { SurveyTemplate, User } from '../../models';
import { SurveyPageLogoComponent } from '../survey-page-logo/survey-page-logo.component';

@Directive({
  selector: '[libSurveyIntro]',
  standalone: true,
})
export class SurveyIntroDirective {}

@Component({
  selector: 'lib-survey-intro',
  templateUrl: './survey-intro.component.html',
  styleUrls: ['./survey-intro.component.scss'],
  standalone: true,
  imports: [CdkStepperModule, NgIf, MatButtonModule, SurveyPageLogoComponent],
})
export class SurveyIntroComponent {
  @Input({ required: true }) template!: SurveyTemplate;
  @Input() user: User | null = null;

  @Output() next = new EventEmitter();
}
