import { inject } from '@angular/core';
import { User as AuthUser } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { AuthService } from '../services';
import { AuthFromTokenService } from '../services/auth-from-token.service';
import { logger } from '../utils/logger';
import { ForbiddenError, UnauthorizedError } from './errors';

export abstract class SharedGuard {
  protected authService = inject(AuthService);
  protected tokenAuthenticator = inject(AuthFromTokenService);
  protected router = inject(Router);

  protected async getCurrentUser(): Promise<AuthUser | undefined> {
    // Priority to the token that may be in the url
    const credentials = await this.tokenAuthenticator.login();
    if (credentials && credentials.user) {
      logger.log('user has logged in with our AuthFromTokenService!!');
      return credentials.user;
    }

    // Check current session
    const user = await firstValueFrom(this.authService.user$);
    if (user) {
      logger.log('user is already logged');
      return user;
    }

    logger.log('not auth');
    return undefined;
  }

  protected redirectOnError(err: any) {
    if (err instanceof UnauthorizedError) {
      return this.router.navigate(['unauthorized']); // or login?
    }
    if (err instanceof ForbiddenError) {
      return this.router.navigate(['forbidden']);
    }
    return;
  }
}
