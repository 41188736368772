<ng-content></ng-content>
<h2 *ngIf="title">
  {{ title }}
</h2>
<h3 *ngIf="subtitle">
  {{ subtitle }}
</h3>
<h3 *ngIf="description">
  {{ description }}
</h3>
