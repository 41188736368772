import { Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { FormFieldBase } from '../../core/form-field/form-field-base.directive';
import { InputBaseComponent } from '../input-base/input-base.component';

@UntilDestroy()
@Component({
  selector: 'lib-survey-number-input',
  template: ` <div>
    <lib-input-base>
      <input type="number" class="form-control" [formControl]="control" />
    </lib-input-base>
  </div>`,
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, InputBaseComponent],
})
export class NumberInputComponent extends FormFieldBase {}
