import { OperatorFunction, filter } from 'rxjs';

/**
 * Filter null or undefined.
 */
export function filterNil<T>(): OperatorFunction<T | null | undefined, T> {
  return (source) =>
    source.pipe(
      filter((value): value is T => !(value === null || value === undefined))
    );
}
