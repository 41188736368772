import { Injectable } from '@angular/core';
import { setDoc } from '@angular/fire/firestore';
import { getDocs, limit, query, where } from '@angular/fire/firestore';
import { EligibleAnswer } from '../../features/eligibility-survey/eligibility-survey.models';
import { SurveyReference } from '../../models';
import { GenericSurveysService } from './generic-surveys.service';

@Injectable()
export class SurveysService extends GenericSurveysService {
  constructor() {
    super({
      surveys: 'surveys',
      surveys_data: 'surveys_data',
      responses: 'responses',
    });
  }

  async saveExistingUserEligibleResponse(
    userId: string,
    answer: EligibleAnswer
  ) {
    // Save answer as user 'already_exist', where docId is the userId
    answer.id = userId;
    const answerRef = this.userAnswerDoc('already_exist', userId);
    await setDoc(answerRef, answer);
    return answerRef;
  }

  saveNonEligibleUserResponse(answer: EligibleAnswer) {
    // Save answer as user 'non_eligible'
    return this.createSurveyAnswer('non_eligible', answer);
  }

  /**
   * Get an answer for a survey that is supposed to be answered only once, such as nps and unsubscribe.
   */
  async getSurveyPersonalAnswer(userId: string, reference: SurveyReference) {
    const responseDocs = await getDocs(
      query(
        this.userAnswersCollection(userId),
        where('ref', '==', reference),
        limit(1)
      )
    );
    return responseDocs.empty ? undefined : responseDocs.docs[0];
  }
}
