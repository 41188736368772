import { Injectable, inject } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs';
import { filterNil } from '../../store';
import { eligibilityActions } from '../eligibility-survey/eligibility-survey.actions';
import { surveyAction } from '../survey/survey.actions';
import { surveyFeature } from '../survey/survey.selectors';
import { AnalyticsEvents } from './analytics.models';
import { AnalyticsResources } from './analytics.resources';

@Injectable()
export class AnalyticsEffects {
  private actions$ = inject(Actions);
  private store = inject(Store);
  private resources = inject(AnalyticsResources);

  surveyEvents$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          surveyAction.loadSurveySuccess,
          surveyAction.changePageSuccess,
          surveyAction.submitResponse
        ),
        concatLatestFrom(() => [
          this.store.select(surveyFeature.selectSurveyAnswer).pipe(filterNil()),
          this.store
            .select(surveyFeature.selectSurveyTemplate)
            .pipe(filterNil()),
        ]),
        tap(([action, answer, survey]) => {
          const AnalyticsEventByAction = {
            [surveyAction.loadSurveySuccess.type]: AnalyticsEvents.SURVEY_OPEN,
            [surveyAction.changePageSuccess.type]: AnalyticsEvents.SURVEY_PAGE,
            [surveyAction.submitResponse.type]: AnalyticsEvents.SURVEY_SUBMIT,
          };
          const eventName = AnalyticsEventByAction[action.type];
          this.resources.sendSurveyEvent(eventName, survey, answer);
        })
      ),
    { dispatch: false }
  );

  eligibleEvents$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(eligibilityActions.eligibilityResult),
        tap(({ result }) => this.resources.sendEligibilityEvent(result))
      ),
    { dispatch: false }
  );
}
