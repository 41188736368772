import { AbstractControl, ValidatorFn } from '@angular/forms';

export const timeValidator: ValidatorFn = (control: AbstractControl) => {
  const hhmm = new RegExp(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/);
  if (typeof control.value === 'string' && !control.value.match(hhmm)) {
    return {
      time: true,
    };
  }
  return null;
};
