<ng-container *ngIf="template">
  <lib-survey-page-logo [title]="template.title" [subtitle]="template.subtitle">
    <img src="/surveys-assets/images/first.svg" />
  </lib-survey-page-logo>
  <p class="lib-survey-intro__description">{{ template.description }}</p>
  <div *ngIf="user">
    <h3>{{ user.email }}</h3>
    <h3>{{ user.firstName }} {{ user.lastName }}</h3>
  </div>
  <div class="lib-survey-intro__buttons-row">
    <button
      mat-raised-button
      color="primary"
      data-test="next-page"
      class="btn action-button continuer-button"
      (click)="next.emit()"
    >
      Continuer
    </button>
  </div>
</ng-container>
