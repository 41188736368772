<ng-container *ngIf="template && form">
  <lib-survey-page-header
    *ngIf="!options?.hideHeader"
    [title]="template.short_title"
  ></lib-survey-page-header>

  <div class="lib-survey-body__content">
    <div>
      <h2>{{ page.title }}</h2>
      <p>{{ page.description }}</p>
    </div>

    <form [formGroup]="form">
      <div
        class="lib-survey-body__question"
        *ngFor="let question of page.questions"
      >
        <div
          [ngSwitch]="question.input_type"
          *ngIf="form.get(question.reference)"
        >
          <h3>{{ question.question }}</h3>

          <h4 *ngIf="question.span1">{{ question.span1 }}</h4>
          <h4 *ngIf="question.span2">{{ question.span2 }}</h4>

          <p
            *ngIf="question.hint && !question.hintUrl"
            class="lib-survey-body__question-hint"
          >
            {{ question.hint }}
          </p>
          <a
            *ngIf="question.hint && question.hintUrl"
            [href]="question.hintUrl"
            target="_blank"
            class="lib-survey-body__question-hint"
          >
            {{ question.hint }}
          </a>

          <lib-survey-email-input
            *ngSwitchCase="QuestionInputType.email"
            [formControlName]="question.reference"
          ></lib-survey-email-input>

          <lib-survey-number-input
            *ngSwitchCase="QuestionInputType.number"
            [formControlName]="question.reference"
          ></lib-survey-number-input>

          <lib-survey-radio-input
            *ngSwitchCase="QuestionInputType.radio"
            [question]="question"
            [formControlName]="question.reference"
          ></lib-survey-radio-input>

          <lib-survey-range-picker
            *ngSwitchCase="QuestionInputType.range"
            [question]="question"
            [formControlName]="question.reference"
            [isScrolling$]="isScrolling$"
          ></lib-survey-range-picker>

          <lib-survey-select-input
            *ngSwitchCase="QuestionInputType.select"
            [question]="question"
            [formControlName]="question.reference"
          ></lib-survey-select-input>

          <lib-survey-step-picker
            *ngSwitchCase="QuestionInputType.step"
            [question]="question"
            [formControlName]="question.reference"
            [isScrolling$]="isScrolling$"
          ></lib-survey-step-picker>

          <lib-survey-text-input
            *ngSwitchCase="QuestionInputType.text"
            [formControlName]="question.reference"
          ></lib-survey-text-input>

          <lib-survey-medications
            *ngSwitchCase="QuestionInputType.medicament"
            [formControlName]="question.reference"
          ></lib-survey-medications>

          <lib-survey-time-picker
            *ngSwitchCase="QuestionInputType.timePicker"
            [formControlName]="question.reference"
          ></lib-survey-time-picker>

          <lib-survey-input-validated-data
            *ngSwitchCase="QuestionInputType.text_with_validation"
            [formControlName]="question.reference"
            [question]="question"
          ></lib-survey-input-validated-data>

          <!-- <lib-survey-group-radio-input
            *ngSwitchCase="QuestionInputType.group_radio"
            [question]="question"
            [form]="form"
          ></lib-survey-group-radio-input>

          <lib-survey-group-select-input
            *ngSwitchCase="QuestionInputType.group_select"
            [question]="question"
            [form]="form"
          ></lib-survey-group-select-input> -->
        </div>
      </div>
      <div class="lib-survey-body__page-progress">
        <div
          *ngFor="let p of template?.pages"
          class="lib-survey-body__page-progress-item"
          [class]="{
            'lib-survey-body__page-progress-item--selected': p === page
          }"
        ></div>
      </div>
      <div class="lib-survey-body__buttons-row" (click)="displayErrors()">
        <button
          mat-button
          data-test="previous-message"
          type="button"
          (click)="onPrevious()"
        >
          <mat-icon color="#301D5E" style="margin-right: 5px"
            >keyboard_backspace</mat-icon
          >
          Retour
        </button>
        <button
          mat-raised-button
          color="primary"
          data-test="next-response-page"
          type="button"
          [disabled]="!form.valid && form.enabled"
          (click)="onNext()"
        >
          <ng-container *ngIf="!isLastPage">Suivant</ng-container>
          <ng-container *ngIf="isLastPage">{{
            options?.submitOnLastQuestion ? options?.submitLabel : 'Suivant'
          }}</ng-container>
        </button>
      </div>
    </form>
  </div>
</ng-container>
