import { Subject } from 'rxjs';
import { distinctUntilChanged, share } from 'rxjs/operators';

export default class ScrollListener {
  private _isScrolling$ = new Subject<boolean>();

  readonly isScrolling$ = this._isScrolling$.pipe(
    distinctUntilChanged(),
    share()
  );

  private scrollTimeout?: number;

  onScroll(_event: Event) {
    if (this.scrollTimeout) {
      clearTimeout(this.scrollTimeout);
    }
    this._isScrolling$.next(true);
    this.scrollTimeout = setTimeout(() => this._isScrolling$.next(false), 100);
  }
}
