import { Directive, ElementRef, HostListener, inject } from '@angular/core';
import { ENVIRONMENT_CONFIG } from '../environment';

@Directive({
  selector: '[libStepperScrollerTop]',
  standalone: true,
})
export class StepperScrollerTopDirective {
  private elementRef = inject(ElementRef);
  private isDeveloperMode = inject(ENVIRONMENT_CONFIG).isDeveloperMode;

  @HostListener('selectionChange')
  selectionChanged() {
    const shouldScroll = !this.isDeveloperMode;
    if (shouldScroll) {
      this.elementRef.nativeElement.scrollIntoView({
        block: 'start',
        inline: 'nearest',
        //behavior: '',
      });
    }
  }
}
