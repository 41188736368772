import { NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map, pairwise, startWith } from 'rxjs';
import { FormFieldBase } from '../../core/form-field/form-field-base.directive';
import { InputBaseComponent } from '../input-base/input-base.component';

@Component({
  selector: 'lib-survey-time-picker',
  template: `<lib-input-base>
      <input
        type="text"
        class="form-control"
        [formControl]="control"
        maxlength="5"
        placeholder="hh:mm"
      />
    </lib-input-base>
    <mat-error *ngIf="control.invalid && (control.dirty || control.touched)">
      <div *ngIf="control.errors?.['time']">
        Heure non valide (format hh:mm)
      </div>
    </mat-error>`,
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    InputBaseComponent,
    NgIf,
    MatFormFieldModule,
  ],
})
@UntilDestroy()
export class TimePickerComponent extends FormFieldBase implements OnInit {
  override ngOnInit() {
    super.ngOnInit();

    this.control.valueChanges
      .pipe(
        startWith(this.control.value),
        pairwise(),
        map(([oldValue, newValue]) => this.onChange(oldValue, newValue)),
        untilDestroyed(this)
      )
      .subscribe();
  }

  private onChange(oldValue: string | null, newValue: string | null) {
    [oldValue, newValue] = [oldValue ?? '', newValue ?? ''];
    let fixedValue: string | undefined = undefined;
    if (newValue?.length < oldValue.length) {
      // Backspace
      if (newValue.length === 3) {
        fixedValue = newValue.slice(0, 2);
      }
    } else {
      // New char
      if (
        newValue.length >= 0 &&
        [3, 4, 5, 6, 7, 8, 9].includes(parseInt(newValue.slice(0, 1)))
      ) {
        fixedValue = '0' + newValue;
      }

      if (newValue.length >= 2 && newValue.indexOf(':') === -1) {
        fixedValue = newValue.slice(0, 2) + ':' + newValue.slice(2);
      }
    }
    if (fixedValue) {
      this.control.setValue(fixedValue);
    }
  }
}
