<section>
  <div [ngTemplateOutlet]="selected ? selected.content : null"></div>

  <footer *ngIf="showFooter">
    <button cdkStepperPrevious>&larr;</button>
    <button
      [class.example-active]="selectedIndex === i"
      *ngFor="let step of steps; let i = index"
      (click)="selectStepByIndex(i)"
    >
      Step {{ i + 1 }}
    </button>
    <button cdkStepperNext>&rarr;</button>
  </footer>
</section>
