import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'lib-survey-page-logo',
  templateUrl: './survey-page-logo.component.html',
  styleUrls: ['./survey-page-logo.component.scss'],
  standalone: true,
  imports: [NgIf],
})
export class SurveyPageLogoComponent {
  @Input() title = '';
  @Input() subtitle = '';
  @Input() description = '';
}
