import { Component } from '@angular/core';

@Component({
  template: ` <div style="text-align: center">
    <img
      style="margin-top: 50px"
      src="/surveys-assets/images/logo.svg"
      alt="remedee"
    />
    <br />
    <h1>Questionnaire envoyé avec succès</h1>
  </div>`,
  standalone: true,
})
export class SuccessfulComponent {}
