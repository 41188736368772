import { Component, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { SurveyLayoutComponent } from '../../components';
import { SurveyComponent } from '../survey';
import { simpleSurveyAction } from './simple-survey.actions';

@Component({
  selector: 'lib-simple-survey',
  templateUrl: './simple-survey.component.html',
  standalone: true,
  imports: [SurveyComponent, SurveyLayoutComponent],
})
export class SimpleSurveyComponent {
  private store = inject(Store);

  onSurveyLoad() {
    this.store.dispatch(simpleSurveyAction.loadSimpleSurvey());
  }

  onPageChange() {
    this.store.dispatch(simpleSurveyAction.saveProgress());
  }

  onSubmit() {
    this.store.dispatch(simpleSurveyAction.submitAnswer());
  }
}
