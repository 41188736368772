import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';

export function conditionalValidator(
  predicate: () => boolean,
  validators: ValidatorFn | ValidatorFn[]
) {
  const validator = Array.isArray(validators)
    ? (Validators.compose(validators) as ValidatorFn)
    : validators;

  return (form: AbstractControl) => {
    if (!form.parent) {
      return null;
    }
    if (predicate()) {
      return validator(form);
    }
    return null;
  };
}
