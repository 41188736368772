import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { getAuth, signInWithCustomToken } from '@angular/fire/auth';
import { firstValueFrom } from 'rxjs';
import { ENVIRONMENT_CONFIG } from '../core';
import { logger } from '../utils/logger';

interface TokenResponse {
  uid: string;
  token: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthFromTokenService {
  private http = inject(HttpClient);
  private baseUrl = inject(ENVIRONMENT_CONFIG).functionsBaseUrl;

  private promise?: ReturnType<typeof this.doLogin>;

  async login() {
    // Make sure we apply the process only once per session
    if (!this.promise) {
      this.promise = this.doLogin();
    }
    return this.promise;
  }

  private async doLogin() {
    const params = this.getParamsFromUrl();
    const idToken = params['token'];
    if (!idToken) {
      return;
    }

    logger.log('AuthFromTokenService login');

    try {
      const customTokenResponse = await firstValueFrom(
        this.getCustomToken(idToken)
      );
      const token = customTokenResponse.token;

      logger.log('signInWithCustomToken', token);

      const auth = getAuth();
      const user = await signInWithCustomToken(auth, token);
      logger.log('signed in as', user);
      return user;
    } catch (err) {
      logger.error(err);
    }
    return undefined;
  }

  private getParamsFromUrl() {
    const hash = window.location.hash.substring(1);
    return Object.fromEntries(hash.split('&').map((part) => part.split('=')));
  }

  getCustomToken(idToken: string) {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', `Bearer ${idToken}`);

    return this.http.post<TokenResponse>(
      this.baseUrl + '/CFUser/user/custom_token',
      {},
      { headers }
    );
  }
}
