import { SurveyAnswer, Unsafe } from '../../models';

export function parseSurveyAnswer(data: Unsafe<SurveyAnswer>) {
  const answer: SurveyAnswer = {
    ...data,
    progressPage: data.progressPage ?? 0,
    isFinished: data.isFinished ?? false,
    responses: data.responses ?? {},
  } as SurveyAnswer;
  return answer;
}
