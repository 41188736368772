import { Environment } from '@remedeelabs/surveys-common';
import { VERSION } from '../version';

export const environment: Environment = {
  name: 'medical-dev',
  production: false,
  firebase: {
    apiKey: 'AIzaSyDnZiEnTk4DONLS291mh5Zyt5bvTFAT1vo',
    authDomain: 'remedee-medical-dev.firebaseapp.com',
    projectId: 'remedee-medical-dev',
    storageBucket: 'remedee-medical-dev.appspot.com',
    messagingSenderId: '278614007999',
    appId: '1:278614007999:web:603208d1990b687a7e260a',
    measurementId: 'G-GKTVTXMCE9',
  },
  sentry: {
    dsn: 'https://e0461d3d683671347ffdbec4d95fff67@o4504690115870720.ingest.us.sentry.io/4508614261276672',
  },
  functionsBaseUrl:
    'https://europe-west1-remedee-medical-dev.cloudfunctions.net',
  version: VERSION,
};
