export const AnalyticsEvents = {
  /** Survey events */
  SURVEY_OPEN: 'SURVEY_OPEN',
  SURVEY_PAGE: 'SURVEY_PAGE',
  SURVEY_SUBMIT: 'SURVEY_SUBMIT',

  /** Misc */
  ELIGIBILITY: 'ELIGIBILITY',
} as const;

export interface SurveyEvent {
  reference: string;
  version: string;
  isFinished: boolean;
  page: number;
  pageTitle: string;
}

export interface EligibilityEvent {
  result: string;
  uid?: string;
}
