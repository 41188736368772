<div>
  <div style="display: flex; justify-content: space-between">
    <div>
      <div *ngFor="let medication of medications; let i = index">
        <div
          style="
            display: flex;
            justify-content: space-between;
            padding: 10px;
            margin: 10px;
            border: 1px solid #ebebeb;
            border-radius: 7px;
            align-items: center;
          "
        >
          <div>
            <p
              style="
                font-family: 'Open Sans';
                font-weight: bold;
                font-size: 16px;
                color: #301d5e;
              "
            >
              {{ medication.medication.name }}
              {{ medication.medication.dosage.dosage }}
              {{ medication.medication.dosage.unit }}
            </p>
            <p
              style="
                font-family: 'Open Sans';
                font-weight: normal;
                font-size: 16px;
                color: #301d5e;
              "
            >
              {{ medication.medication.quantity.qte }}
              {{ medication.medication.quantity.unit }} par semaine
            </p>
          </div>
          <div>
            <button
              data-test="remove-medication"
              style="
                font-family: 'Open Sans';
                margin-left: 20px;
                border: 1px solid #301d5e;
                border-radius: 8px;
              "
              (click)="removeMedication(i)"
              [disabled]="control.disabled"
            >
              <mat-icon>close</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <button
    data-test="show-new-medication"
    *ngIf="!showMedicationForm"
    style="
      font-family: 'Open Sans';
      box-shadow: 0 4px 6px #0000002c;
      border-radius: 9px;
      opacity: 1;
    "
    class="btn action-button continuer-button"
    (click)="showNewMedication()"
    [disabled]="control.disabled"
  >
    Ajouter un médicament
  </button>

  <div *ngIf="showMedicationForm" class="modal-medicine">
    <div class="mat-typography" style="width: 369px">
      <label
        style="
          font-family: 'Open Sans';
          color: #301d5e;
          font-size: 24px;
          margin-bottom: 20px;
        "
      >
        Ajout d'un traitement
      </label>

      <form #innerForm="ngForm">
        <label class="font">Nom du médicament</label>
        <input
          class="input"
          style="font-family: 'Open Sans'; color: #9d45db"
          required
          placeholder="Nom de médicament"
          [(ngModel)]="newMedication.medication.name"
          name="name"
          [disabled]="control.disabled"
        />

        <label class="font">Dosage de la molécule</label>
        <div style="display: flex; justify-content: space-between">
          <input
            class="input"
            style="font-family: 'Open Sans'; width: 30%; color: #9d45db"
            type="number"
            required
            placeholder="Dosage de la molécule"
            [disabled]="control.disabled"
            [(ngModel)]="newMedication.medication.dosage.dosage"
            name="dosage"
          />

          <mat-radio-group
            aria-label="Choisir une option"
            style="
              width: 70%;
              display: flex;
              justify-content: space-evenly;
              align-items: center;
            "
            required
            [disabled]="control.disabled"
            [(ngModel)]="newMedication.medication.dosage.unit"
            name="dosage-unit"
          >
            <mat-radio-button
              value="mg"
              style="
                font-family: 'Open Sans';
                font-size: 15px;
                font-weight: normal;
              "
              [ngStyle]="{
                color:
                  newMedication.medication.dosage.unit === 'mg'
                    ? '#9D45DB'
                    : '#301D5E'
              }"
            >
              mg
            </mat-radio-button>
            <mat-radio-button
              value="mg/ml"
              style="
                font-family: 'Open Sans';
                font-size: 15px;
                font-weight: normal;
              "
              [disabled]="control.disabled"
              [ngStyle]="{
                color:
                  newMedication.medication.dosage.unit === 'mg/ml'
                    ? '#9D45DB'
                    : '#301D5E'
              }"
            >
              mg/ml
            </mat-radio-button>
          </mat-radio-group>
        </div>

        <br />

        <label class="font">Quantité (par semaine)</label>
        <div style="display: flex; justify-content: space-between">
          <input
            class="input"
            style="font-family: 'Open Sans'; width: 30%; color: #9d45db"
            type="number"
            required
            [disabled]="control.disabled"
            placeholder="Quantité (par semaine)"
            [(ngModel)]="newMedication.medication.quantity.qte"
            name="qte"
          />

          <mat-radio-group
            style="
              width: 70%;
              display: flex;
              justify-content: space-evenly;
              align-items: center;
            "
            aria-label="Choisir une option"
            required
            [disabled]="control.disabled"
            [(ngModel)]="newMedication.medication.quantity.unit"
            name="quantity-unit"
          >
            <mat-radio-button
              value="cp"
              style="
                font-family: 'Open Sans';
                color: #301d5e;
                font-weight: normal;
                font-size: 15px;
              "
              [ngStyle]="{
                color:
                  newMedication.medication.quantity.unit === 'cp'
                    ? '#9D45DB'
                    : '#301D5E'
              }"
            >
              cp
            </mat-radio-button>
            <mat-radio-button
              value="gouttes"
              [disabled]="control.disabled"
              style="
                font-family: 'Open Sans';
                color: #301d5e;
                font-weight: normal;
                font-size: 15px;
              "
              [ngStyle]="{
                color:
                  newMedication.medication.quantity.unit === 'gouttes'
                    ? '#9D45DB'
                    : '#301D5E'
              }"
            >
              gouttes
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </form>
    </div>

    <div style="display: flex; justify-content: left">
      <button
        data-test="hide-new-medication"
        class="btn action-button retour-button"
        (click)="hideNewMedication()"
        [disabled]="control.disabled"
      >
        <mat-icon color="#301D5E" style="margin-right: 5px"
          >keyboard_backspace</mat-icon
        >
        Retour
      </button>
      <button
        data-test="add-medication"
        [disabled]="innerForm.form.invalid"
        (click)="addMedication()"
        class="btn action-button continuer-button"
        [disabled]="control.disabled"
      >
        Ajouter
      </button>
    </div>
  </div>
</div>
