import { AbstractControl, ValidatorFn } from '@angular/forms';

export function inputDataValidator(data: string[]): ValidatorFn {
  return (control: AbstractControl<string>) => {
    if (data.some((value) => value === control.value)) {
      return null;
    }
    return {
      inputData: true,
    };
  };
}
