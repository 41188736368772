import { AsyncPipe, NgIf } from '@angular/common';
import { Component, Input, inject } from '@angular/core';
import { ErrorHandlerService } from '../../services';

@Component({
  template: `<h1>{{ title }}</h1>
    <p *ngIf="lastError | async as error">{{ error }}</p>`,
  standalone: true,
  imports: [AsyncPipe, NgIf],
  selector: 'lib-error-page',
})
export class BaseErrorPageComponent {
  @Input() title = 'Erreur';
  readonly lastError = inject(ErrorHandlerService).lastError;
}

@Component({
  template: `<lib-error-page title="Erreur"></lib-error-page>`,
  standalone: true,
  imports: [BaseErrorPageComponent],
  selector: 'lib-any-error-page',
})
export class ErrorPageComponent {}

@Component({
  template: `<lib-error-page title="Accès non authorisé"></lib-error-page>`,
  standalone: true,
  imports: [BaseErrorPageComponent],
  selector: 'lib-forbidden-page',
})
export class ForbiddenPageComponent {}

@Component({
  template: `<lib-error-page title="Accès non authorisé"></lib-error-page>`,
  standalone: true,
  imports: [BaseErrorPageComponent],
  selector: 'lib-unauthorized-page',
})
export class UnauthorizedPageComponent {}
