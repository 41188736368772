import { Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormFieldBase } from '../../core/form-field/form-field-base.directive';
import { InputBaseComponent } from '../input-base/input-base.component';
import { scrollTo } from '../utils';

@Component({
  selector: 'lib-survey-text-input',
  template: `
    <lib-input-base>
      <input
        #input
        mat-input
        type="text"
        class="form-control"
        [formControl]="control"
        (focus)="scrollTo(input)"
      />
    </lib-input-base>
  `,
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, InputBaseComponent],
})
export class TextInputComponent extends FormFieldBase {
  readonly scrollTo = scrollTo;
}
