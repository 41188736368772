import { Injectable } from '@angular/core';
import { GenericSurveysService } from './generic-surveys.service';

@Injectable({ providedIn: 'root' })
export class TherapeuticSurveysService extends GenericSurveysService {
  constructor() {
    super({
      surveys: 'therapeutic_surveys',
      surveys_data: 'therapeutic_surveys_data',
      responses: 'therapeutic_surveys_responses',
    });
  }
}
