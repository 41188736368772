<ng-container *ngIf="template">
  <lib-survey-page-header
    [title]="template.short_title"
  ></lib-survey-page-header>
  <lib-survey-page-logo
    [title]="template.ending_title"
    [subtitle]="template.ending_subtitle"
    [description]="template.ending_description"
  >
    <img src="/surveys-assets/images/check.svg" />
  </lib-survey-page-logo>
  <div class="lib-survey-outro__buttons-row">
    <button
      mat-raised-button
      color="primary"
      data-test="send-response"
      [disabled]="isDisabled"
      class="lib-survey-outro__buttons-row__send-btn"
      (click)="sendResponse()"
    >
      Envoyer
    </button>
    <button
      mat-button
      data-test="previous-page"
      class="lib-survey-outro__buttons-row__back-btn"
      (click)="previousPage()"
    >
      <mat-icon color="#301D5E">keyboard_backspace</mat-icon>
      Retour
    </button>
  </div>
</ng-container>
