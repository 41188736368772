import { Injectable, inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { LogicError } from '../../errors';
import { logger } from '../../utils/logger';
import { GenericSurveysService } from './generic-surveys.service';
import { SurveysService } from './surveys.service';
import { TherapeuticSurveysService } from './therapeutic-surveys.service';

export type ProjectContext = 'wellness' | 'medical';

export type SurveyContext = 'surveys' | 'therapeutic_surveys';

export interface ContextAwareRouteParam {
  projectContext?: ProjectContext;
  surveyContext?: SurveyContext;
}

export const resolveSurveyContext: ResolveFn<SurveyContext | undefined> = (
  route,
  state
) => inject(ContextAwareSurveysService).resolve(route, state);

@Injectable()
export class ContextAwareSurveysService {
  private services: Record<SurveyContext, GenericSurveysService> = {
    surveys: inject(SurveysService),
    therapeutic_surveys: inject(TherapeuticSurveysService),
  };

  private context?: ContextAwareRouteParam;

  resolve: ResolveFn<SurveyContext | undefined> = (route) => {
    const context = route.data as ContextAwareRouteParam;
    const surveyContext = context.surveyContext;
    if (surveyContext && surveyContext in this.services) {
      this.context = context;
    } else {
      this.context = undefined;
    }
    logger.log('use context', this.context);
    return surveyContext;
  };

  getProjectContext() {
    return this.context?.projectContext;
  }

  getService() {
    const service = this.context?.surveyContext
      ? this.services[this.context.surveyContext]
      : undefined;
    if (!service) {
      throw new LogicError('No context found for our survey service');
    }
    return service;
  }
}
