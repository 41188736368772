import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, switchMap, tap } from 'rxjs';
import { ErrorHandlerService } from '../../services';
import { current } from '../../store';
import { surveyAction } from './survey.actions';
import { surveyFeature } from './survey.selectors';

@Injectable()
export class SurveyEffects {
  private actions$ = inject(Actions);
  private store = inject(Store);
  private errorHandler = inject(ErrorHandlerService);

  nextPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(surveyAction.nextPage),
      switchMap(() =>
        this.store.select(surveyFeature.selectSurveyAnswer).pipe(current())
      ),
      map((answer) =>
        surveyAction.changePage({
          progressPage: answer.progressPage + 1,
        })
      )
    )
  );

  errorHandler$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(surveyAction.handleError),
        tap(({ error }) => this.errorHandler.handleError(error))
      ),
    { dispatch: false }
  );
}
