import { Injectable, inject } from '@angular/core';
import { SurveyAnswer } from '../../models/surveys';
import { ContextAwareSurveysService } from '../../services/surveys';
import { parseSurveyAnswer } from '../survey/survey.parser';

@Injectable()
export class SimpleSurveyResources {
  private proxy = inject(ContextAwareSurveysService);

  async getSurveyData(userId: string, responseId: string) {
    const service = this.proxy.getService();

    const answerSnap = await service.getSurveyAnswer(userId, responseId);
    const surveyAnswer = parseSurveyAnswer(answerSnap.data());

    const surveyTemplate = (
      await service.getSurveyTemplate(surveyAnswer.ref, surveyAnswer.version)
    ).data();

    return { surveyAnswer, surveyTemplate };
  }

  async updateSurveyAnswer(
    userId: string,
    responseId: string,
    answer: SurveyAnswer
  ) {
    const service = this.proxy.getService();
    await service.updateSurveyAnswer(userId, responseId, answer);
  }
}
