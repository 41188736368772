import { ErrorHandler, Injectable, inject } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CustomError } from '../errors';
import { logger } from '../utils/logger';
import { SentryService } from './sentry.service';
import { SnackbarService } from './snackbar.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService implements ErrorHandler {
  private snackbar = inject(SnackbarService);
  private sentry = inject(SentryService, { optional: true });

  private _lastError = new BehaviorSubject<string | undefined>(undefined);
  readonly lastError = this._lastError.asObservable();

  handleError(error: any) {
    if (error.promise && error.rejection) {
      error = error.rejection;
    }

    if (this.shouldReport(error)) {
      this.doReport(error);
    }

    if (this.shouldDisplay(error)) {
      const message = this.doDisplay(error);
      this.setLastError(message);
    } else {
      this.setLastError(undefined);
    }
  }

  private setLastError(message: string | undefined) {
    this._lastError.next(message);
  }

  private shouldReport(_error: any) {
    return true;
  }

  private shouldDisplay(error: any) {
    const message = error.message;

    // Some exceptions here
    if (message?.includes('analytics/cookies-not-enabled')) {
      return false;
    }

    return true;
  }

  private doReport(error: any) {
    this.sentry?.handleError(error);
  }

  private doDisplay(error: any) {
    logger.error('handleError', error);
    let message: string = error.message;

    this._lastError.next(message);

    if (!(error instanceof CustomError)) {
      message = 'Unexpected error: ' + message;
    }

    error.isWarning
      ? this.snackbar.warning(message, null) // Keep open
      : this.snackbar.error(message);

    return message;
  }
}
