import { NgFor, NgIf, NgSwitch, NgSwitchCase } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { EMPTY, Observable } from 'rxjs';
import { SurveyOptions } from '../../features';
import { QuestionInputType, SurveyPage, SurveyTemplate } from '../../models';
import { PageForm } from '../../services';
import { EmailInputComponent } from '../email-input/email-input.component';
import { InputValidatedDataComponent } from '../input-validated-data/input-validated-data.component';
import { MedicationsComponent } from '../medications/medications.component';
import { NumberInputComponent } from '../number-input/number-input.component';
import { RadioInputComponent } from '../radio-input/radio-input.component';
import { RangePickerComponent } from '../range-picker/range-picker.component';
import { SelectInputComponent } from '../select-input/select-input.component';
import { StepPickerComponent } from '../step-picker/step-picker.component';
import { SurveyPageHeaderComponent } from '../survey-page-header/survey-page-header.component';
import { TextInputComponent } from '../text-input/text-input.component';
import { TimePickerComponent } from '../time-picker/time-picker.component';

@Component({
  selector: 'lib-survey-body',
  templateUrl: './survey-body.component.html',
  styleUrls: ['./survey-body.component.scss'],
  standalone: true,
  imports: [
    NgSwitch,
    NgIf,
    NgFor,
    NgSwitchCase,
    MatIconModule,
    EmailInputComponent,
    MedicationsComponent,
    NumberInputComponent,
    RadioInputComponent,
    RangePickerComponent,
    SelectInputComponent,
    StepPickerComponent,
    // GroupRadioInputComponent,
    // GroupSelectInputComponent,
    TextInputComponent,
    TimePickerComponent,
    SurveyPageHeaderComponent,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
    InputValidatedDataComponent,
  ],
})
export class SurveyBodyComponent {
  @Input({ required: true }) template!: SurveyTemplate;
  @Input({ required: true }) page!: SurveyPage;
  @Input({ required: true }) form!: PageForm;
  @Input() options?: SurveyOptions;
  @Input() isLastPage = false;
  @Input() isDisabled = false;
  @Input() isScrolling$: Observable<boolean> = EMPTY;

  @Output() next = new EventEmitter();
  @Output() previous = new EventEmitter();
  @Output() send = new EventEmitter();

  readonly QuestionInputType = QuestionInputType;

  onPrevious() {
    this.previous.emit();
  }

  onNext() {
    const form = this.form;
    if (!form.valid && !form.disabled) {
      return;
    }

    this.isLastPage && this.options?.submitOnLastQuestion
      ? this.send.emit()
      : this.next.emit();
  }

  displayErrors() {
    const form = this.form;
    form.markAllAsTouched();
    Object.values(form.controls).forEach((control) =>
      control.updateValueAndValidity({ onlySelf: true })
    );
  }
}
