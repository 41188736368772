declare let Android: any;

export function sendToMobile(message: string) {
  if (isAndroid()) {
    return sendAndroid(message);
  }
  if (isApple()) {
    return sendApple(message);
  }
  return false;
}

export function isAndroid() {
  return (
    navigator.userAgent.match(/Android/i) && typeof Android !== 'undefined'
  );
}

export function sendAndroid(message: string) {
  Android.sendData(message);
  return true;
}

export function isApple() {
  return navigator.userAgent.match(/(iPod|iPhone|iPad)/);
}

export function sendApple(message: string) {
  const messageHandlers = (window as any).webkit?.messageHandlers;
  if (messageHandlers) {
    messageHandlers.callbackHandler.postMessage(JSON.stringify(message));
    return true;
  }
  return false;
}
