import { Injectable } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {
  QuestionInputType,
  ResponseValue,
  SurveyQuestion,
  SurveyResponses,
  SurveyTemplate,
} from '../models';
import { inputDataValidator } from '../validators/input-data-validator';
import { timeValidator } from '../validators/time-validator';

export type QuestionControl = FormControl<ResponseValue>;
export type PageForm = FormGroup<{ [reference: string]: QuestionControl }>;
export type SurveyForm = FormArray<PageForm>;

@Injectable({ providedIn: 'root' })
export class SurveyBuilder {
  constructor(private formBuilder: FormBuilder) {}

  buildSurveyForm(survey: SurveyTemplate, responses: SurveyResponses) {
    return this.formBuilder.array(
      survey.pages.map((page) => this.buildPageForm(page.questions, responses))
    );
  }

  /**
   * Build the form with the current responses and the appropriate validators.
   */
  buildPageForm(questions: SurveyQuestion[], responses: SurveyResponses) {
    const controls: { [key: string]: FormControl<ResponseValue> } = {};

    for (const question of questions) {
      controls[question.reference] = this.buildQuestionControl(
        question,
        responses[question.reference]
      );
      if (question.input_type === 'timePicker') {
        if (controls[question.reference].value) {
          controls[question.reference].markAsTouched();
          controls[question.reference].updateValueAndValidity();
        }
      }
    }

    const form = this.formBuilder.group(controls) as PageForm;
    return form;
  }

  /**
   * Build the form control for one question.
   */
  buildQuestionControl(question: SurveyQuestion, response: ResponseValue) {
    response = response !== undefined ? response : question.default;
    const validators = this.getValidators(question);
    if (question.input_type === 'text_with_validation') {
      return this.formBuilder.control<ResponseValue>(response, {
        validators: validators,
        updateOn: 'blur',
      });
    }
    return this.formBuilder.control<ResponseValue>(response, validators);
  }

  private getValidators(question: SurveyQuestion) {
    const validators: ValidatorFn[] = [];

    switch (question.input_type) {
      case QuestionInputType.text:
      case QuestionInputType.medicament:
        if (question.optional === false) {
          validators.push(Validators.required);
        }
        break;

      case QuestionInputType.email:
        validators.push(Validators.email);
        if (!question.optional) {
          validators.push(Validators.required);
        }
        break;

      case QuestionInputType.timePicker:
        validators.push(timeValidator);
        if (!question.optional) {
          validators.push(Validators.required);
        }
        break;

      case QuestionInputType.text_with_validation:
        validators.push(
          inputDataValidator(
            question.options?.map((option) => option.value) ?? []
          )
        );
        break;

      default:
        if (!question.optional) {
          validators.push(Validators.required);
        }
    }

    return validators;
  }
}
