import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'lib-survey-page-header',
  templateUrl: './survey-page-header.component.html',
  styleUrls: ['./survey-page-header.component.scss'],
  standalone: true,
  imports: [NgIf],
})
export class SurveyPageHeaderComponent {
  @Input() title = '';
}
