import { Component, OnInit, inject } from '@angular/core';
import { SnackbarService } from '../../services';

@Component({
  template: `<h1>Page non trouvée.</h1>`,
  standalone: true,
})
export class NotFoundPageComponent implements OnInit {
  private snackbar = inject(SnackbarService);

  ngOnInit() {
    this.snackbar.warning('Page non trouvée');
  }
}
