import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormFieldBase } from '../../core/form-field/form-field-base.directive';
import { SurveyQuestion } from '../../models';
import { InputBaseComponent } from '../input-base/input-base.component';

@Component({
  selector: 'lib-survey-input-validated-data',
  standalone: true,
  imports: [
    CommonModule,
    InputBaseComponent,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
  ],
  template: `<div>
      <lib-input-base>
        <input type="text" class="form-control" [formControl]="control" />
      </lib-input-base>
    </div>

    <mat-error *ngIf="control.invalid && (control.dirty || control.touched)">
      <div *ngIf="control.errors?.['inputData']">
        {{ question.error_message }}
      </div>
    </mat-error>`,
})
export class InputValidatedDataComponent extends FormFieldBase {
  @Input() question!: SurveyQuestion;
}
