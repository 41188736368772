import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { sendToMobile } from '../../utils';

@Component({
  selector: 'lib-finished',
  templateUrl: './finished.component.html',
  styleUrls: ['./finished.component.css'],
  standalone: true,
  imports: [MatButtonModule],
})
export class FinishedComponent {
  retour() {
    sendToMobile('Terminer');
  }
}
