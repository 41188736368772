import { Injectable, inject } from '@angular/core';
import { Auth, ParsedToken, user } from '@angular/fire/auth';
import 'firebase/auth';
import { from, of } from 'rxjs';
import {
  distinctUntilChanged,
  map,
  shareReplay,
  switchMap,
} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly fireAuth = inject(Auth);

  /**
   * Current user from Firebase Auth.
   */
  readonly user$ = user(this.fireAuth).pipe(shareReplay({ refCount: false }));

  /**
   * Current user Id.
   */
  readonly userId$ = this.user$.pipe(
    map((user) => user?.uid),
    distinctUntilChanged()
  );

  /**
   * Returns true when user is logged in.
   */
  readonly isLoggedIn$ = this.userId$.pipe(map((userId) => !!userId));

  readonly claims$ = this.user$.pipe(
    switchMap((user) =>
      user
        ? from(user.getIdTokenResult()).pipe(map(({ claims }) => claims))
        : of({} as ParsedToken)
    )
  );
}
