import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RouterStateUrl } from './custom-route-serializer';

export const getRouterReducerState = createFeatureSelector<{
  state: RouterStateUrl;
}>('router');
export const selectRouteParams = (param: string) => {
  return createSelector(
    getRouterReducerState,
    (routerReducerState) =>
      routerReducerState.state.params?.[param] as string | undefined
  );
};

export const selectRouteQueryParams = (param: string) => {
  return createSelector(
    getRouterReducerState,
    (routerReducerState) =>
      routerReducerState.state.queryParams?.[param] as string | undefined
  );
};
