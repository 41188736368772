import { Timestamp } from '@angular/fire/firestore';

/**
 * Survey reference, aka a string that identifies the type of survey.
 * Example: fiq, psqi, ...
 *
 * Note: we could simply use a string here, but this dedicated type clearly identifies
 * such reference, shared by different models (surveys, program...) and used in our services.
 */
export type SurveyReference = string;

/**
 * Empty document that supports the different versions of templates.
 */
export interface SurveyTemplateReference {
  id: SurveyReference;
}

/**
 * A survey template.
 */
export interface SurveyTemplate {
  reference: SurveyReference;
  forCoach?: boolean;
  isCustomEntrySurvey?: boolean;
  short_title: string;
  version: string;
  title: string;
  subtitle: string;
  description: string;
  ending_title: string;
  ending_subtitle: string;
  ending_description: string;
  text?: string;
  policy?: string;
  question?: object;
  pages: SurveyPage[];
}

/**
 * A page within a SurveyTemplate.
 */
export interface SurveyPage {
  reference: string;
  title: string;
  description: string;
  questions: SurveyQuestion[];
}

export interface SurveyQuestion {
  reference: string;
  question: string;
  hint?: string;
  hintUrl?: string;
  input_type: QuestionInputTypeType;
  options?: SurveyQuestionOption[];
  optional?: boolean;
  min?: number;
  max?: number;
  default?: number;
  step?: number;
  minLabel?: string;
  maxLabel?: string;
  comment?: string;
  span1?: string;
  span2?: string;
  display_condition?: {
    ref: string;
    values: string[];
  };
  error_message?: string;
}

/**
 * Option for questions with type 'select'
 */
export interface SurveyQuestionOption {
  name: string;
  value: string;
  children?: any[];
}

/**
 * Input type used by a SurveyQuestion.
 */
export const QuestionInputType = {
  email: 'email',
  medicament: 'medicament',
  number: 'number',
  radio: 'radio',
  range: 'range',
  select: 'select',
  step: 'step',
  text: 'text',
  timePicker: 'timePicker',
  group_radio: 'group_radio',
  group_select: 'group_select',
  text_with_validation: 'text_with_validation',
} as const;

export type QuestionInputTypeType =
  (typeof QuestionInputType)[keyof typeof QuestionInputType];

/**
 * An answer from a user to a Survey.
 */
export interface SurveyAnswer {
  id: string;
  ref: SurveyReference;
  version: string;
  language?: string;
  shortTitle: string;
  pageCount: number;
  progressPage: number;
  isFinished: boolean;
  completionReason?: CompletionReasonType;
  creationDate: Timestamp;
  modificationDate: Timestamp;
  responseDate?: Timestamp;
  responses: SurveyResponses;
}

export type PartialSurveyAnswer = Exclude<SurveyAnswer, 'responses'>;

export interface SurveysStats {
  userId: string;
  coachId: string | null;
  totalCount: number;
  surveys: PartialSurveyAnswer[];
  // and more stats to be completed here
}
/**
 * A CompletionReason used in a SurveyAnswer, when it is finished.
 */
export const CompletionReason = {
  answered: 'answered',
  expired: 'expired',
} as const;

export type CompletionReasonType =
  (typeof CompletionReason)[keyof typeof CompletionReason];

/**
 * Responses within a SurveyAnswer.
 */
export interface SurveyResponses {
  [reference: string]: ResponseValue;
}

export type ResponseValue =
  | string
  | number
  | string[]
  | Medication[]
  | null
  | undefined;

/**
 * Medication in a response for type 'medicament'
 */
export interface Medication {
  medication: {
    name: string;
    dosage: {
      dosage: number;
      unit: string;
    };
    quantity: {
      qte: number;
      unit: string;
    };
  };
}
